import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateApplication } from "ee/actions/applicationActions";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import type { UpdateApplicationPayload } from "ee/api/ApplicationApi";
import LazyCodeEditor from "components/editorComponents/LazyCodeEditor";
import {
  CodeEditorBorder,
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/editorComponents/CodeEditor/EditorConfig";

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%;
`;

const CustomStyleSettings: React.FC = () => {
  const dispatch = useDispatch();
  const applicationId = useSelector(getCurrentApplicationId);
  const application = useSelector(getCurrentApplication);
  const [customStyle, setCustomStyle] = useState(
    application?.customStyle || "",
  );

  const updateCustomStyle = useCallback(
    debounce((style: string) => {
      const payload: UpdateApplicationPayload = { currentApp: true };
      payload.customStyle = style;
      dispatch(updateApplication(applicationId, payload));
    }, 50),
    [applicationId, dispatch],
  );

  return (
    <SettingWrapper>
      <LazyCodeEditor
        border={CodeEditorBorder.ALL_SIDE}
        className="t--datasource-editor"
        focusElementName={"customStyle"}
        height={"500px"}
        input={{
          value: customStyle || "",
          onChange: (value) => {
            setCustomStyle(value);
            updateCustomStyle(value);
          },
        }}
        mode={EditorModes.CSS}
        showLineNumbers
        size={EditorSize.EXTENDED}
        tabBehaviour={TabBehaviour.INDENT}
        theme={EditorTheme.LIGHT}
      />
    </SettingWrapper>
  );
};

export default CustomStyleSettings;
