/* eslint-disable @typescript-eslint/no-restricted-imports */
import type { ReduxAction } from "actions/ReduxActionTypes";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import {
  type PersistentZObjectDefinition,
  ZAvailableExtensions,
  type ZCustomEvent,
  type ZCustomFieldDefinition,
  type ZWorkflow,
} from "ee/types/zuora";
import { createImmerReducer } from "utils/ReducerUtils";

interface ComponentState<T> {
  data: T | null;
  lastSaveAttemptSuccessful: boolean | null;
  lastLoadAttemptSuccessful: boolean | null;
}

interface ZuoraObjectComponentState
  extends ComponentState<PersistentZObjectDefinition[]> {}

interface ZuoraCustomFieldComponentState
  extends ComponentState<ZCustomFieldDefinition[]> {}

interface ZuoraCustomEventComponentState
  extends ComponentState<ZCustomEvent[]> {}

interface ZuoraWorkflowComponentState extends ComponentState<ZWorkflow[]> {}

export interface ZuoraComponentsReduxState {
  componentManagerIsLoaded: boolean;
  [ZAvailableExtensions.CustomObject]: ZuoraObjectComponentState;
  [ZAvailableExtensions.CustomField]: ZuoraCustomFieldComponentState;
  [ZAvailableExtensions.CustomEvent]: ZuoraCustomEventComponentState;
  [ZAvailableExtensions.Workflow]: ZuoraWorkflowComponentState;
}

const initialState: ZuoraComponentsReduxState = {
  componentManagerIsLoaded: false,
  [ZAvailableExtensions.CustomObject]: {
    data: null,
    lastSaveAttemptSuccessful: null,
    lastLoadAttemptSuccessful: null,
  },
  [ZAvailableExtensions.CustomField]: {
    data: null,
    lastSaveAttemptSuccessful: null,
    lastLoadAttemptSuccessful: null,
  },
  [ZAvailableExtensions.CustomEvent]: {
    data: null,
    lastSaveAttemptSuccessful: null,
    lastLoadAttemptSuccessful: null,
  },
  [ZAvailableExtensions.Workflow]: {
    data: null,
    lastSaveAttemptSuccessful: null,
    lastLoadAttemptSuccessful: null,
  },
};

const objectHandlers = {
  [ReduxActionTypes.ZUORA_RESET_OBJECT_COMPONENT_SAVE_STATUS]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customObjectLabel = ZAvailableExtensions.CustomObject;

    return {
      ...state,
      [customObjectLabel]: {
        ...state[customObjectLabel],
        lastSaveAttemptSuccessful: null,
      },
    };
  },

  [ReduxActionTypes.ZUORA_RESET_OBJECT_COMPONENT_LOAD_STATUS]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customObjectLabel = ZAvailableExtensions.CustomObject;

    return {
      ...state,
      [customObjectLabel]: {
        ...state[customObjectLabel],
        lastLoadAttemptSuccessful: null,
      },
    };
  },

  [ReduxActionTypes.ZUORA_SAVE_OBJECT_COMPONENTS_SUCCESS]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<PersistentZObjectDefinition[]>,
  ) => {
    const customObjectLabel = ZAvailableExtensions.CustomObject;

    return {
      ...state,
      [customObjectLabel]: {
        ...state[customObjectLabel],
        data: action.payload,
        lastSaveAttemptSuccessful: true,
      },
    };
  },
  [ReduxActionTypes.ZUORA_SAVE_OBJECT_COMPONENTS_FAILURE]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customObjectLabel = ZAvailableExtensions.CustomObject;

    return {
      ...state,
      [customObjectLabel]: {
        ...state[customObjectLabel],
        lastSaveAttemptSuccessful: false,
      },
    };
  },

  // LOAD OBJECT COMPONENTS
  [ReduxActionTypes.ZUORA_LOAD_OBJECT_COMPONENTS_SUCCESS]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<PersistentZObjectDefinition[]>,
  ) => {
    const customObjectLabel = ZAvailableExtensions.CustomObject;

    return {
      ...state,
      [customObjectLabel]: {
        ...state[customObjectLabel],
        data: [...action.payload],
        lastLoadAttemptSuccessful: true,
      },
    };
  },
  [ReduxActionTypes.ZUORA_LOAD_OBJECT_COMPONENTS_FAILURE]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customObjectLabel = ZAvailableExtensions.CustomObject;

    return {
      ...state,
      [customObjectLabel]: {
        ...state[customObjectLabel],
        lastLoadAttemptSuccessful: false,
      },
    };
  },
};

const customFieldHandlers = {
  [ReduxActionTypes.ZUORA_RESET_CUSTOM_FIELD_SAVE_STATUS]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customFieldLabel = ZAvailableExtensions.CustomField;

    return {
      ...state,
      [customFieldLabel]: {
        ...state[customFieldLabel],
        lastSaveAttemptSuccessful: null,
      },
    };
  },

  [ReduxActionTypes.ZUORA_RESET_CUSTOM_FIELD_LOAD_STATUS]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customFieldLabel = ZAvailableExtensions.CustomField;

    return {
      ...state,
      [customFieldLabel]: {
        ...state[customFieldLabel],
        lastLoadAttemptSuccessful: null,
      },
    };
  },

  [ReduxActionTypes.ZUORA_SAVE_CUSTOM_FIELDS_SUCCESS]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<ZCustomFieldDefinition[]>,
  ) => {
    const customFieldLabel = ZAvailableExtensions.CustomField;

    return {
      ...state,
      [customFieldLabel]: {
        ...state[customFieldLabel],
        data: action.payload,
        lastSaveAttemptSuccessful: true,
      },
    };
  },

  [ReduxActionTypes.ZUORA_SAVE_CUSTOM_FIELDS_FAILURE]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customFieldLabel = ZAvailableExtensions.CustomField;

    return {
      ...state,
      [customFieldLabel]: {
        ...state[customFieldLabel],
        lastSaveAttemptSuccessful: false,
      },
    };
  },

  [ReduxActionTypes.ZUORA_LOAD_CUSTOM_FIELDS_SUCCESS]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<ZCustomFieldDefinition[]>,
  ) => {
    const customFieldLabel = ZAvailableExtensions.CustomField;

    return {
      ...state,
      [customFieldLabel]: {
        ...state[customFieldLabel],
        data: action.payload,
        lastLoadAttemptSuccessful: true,
      },
    };
  },

  [ReduxActionTypes.ZUORA_LOAD_CUSTOM_FIELDS_FAILURE]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customFieldLabel = ZAvailableExtensions.CustomField;

    return {
      ...state,
      [customFieldLabel]: {
        ...state[customFieldLabel],
        lastLoadAttemptSuccessful: false,
      },
    };
  },
};

const customEventHandlers = {
  [ReduxActionTypes.ZUORA_RESET_CUSTOM_EVENT_SAVE_STATUS]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customEventLabel = ZAvailableExtensions.CustomEvent;

    return {
      ...state,
      [customEventLabel]: {
        ...state[customEventLabel],
        lastSaveAttemptSuccessful: null,
      },
    };
  },

  [ReduxActionTypes.ZUORA_RESET_CUSTOM_EVENT_LOAD_STATUS]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customEventLabel = ZAvailableExtensions.CustomEvent;

    return {
      ...state,
      [customEventLabel]: {
        ...state[customEventLabel],
        lastLoadAttemptSuccessful: null,
      },
    };
  },

  [ReduxActionTypes.ZUORA_SAVE_CUSTOM_EVENTS_SUCCESS]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<ZCustomEvent[]>,
  ) => {
    const customEventLabel = ZAvailableExtensions.CustomEvent;

    return {
      ...state,
      [customEventLabel]: {
        ...state[customEventLabel],
        data: action.payload,
        lastSaveAttemptSuccessful: true,
      },
    };
  },
  [ReduxActionTypes.ZUORA_SAVE_CUSTOM_EVENTS_FAILURE]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customEventLabel = ZAvailableExtensions.CustomEvent;

    return {
      ...state,
      [customEventLabel]: {
        ...state[customEventLabel],
        lastSaveAttemptSuccessful: false,
      },
    };
  },
  [ReduxActionTypes.ZUORA_LOAD_CUSTOM_EVENTS_SUCCESS]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<ZCustomEvent[]>,
  ) => {
    const customEventLabel = ZAvailableExtensions.CustomEvent;

    return {
      ...state,
      [customEventLabel]: {
        ...state[customEventLabel],
        data: action.payload,
        lastLoadAttemptSuccessful: true,
      },
    };
  },
  [ReduxActionTypes.ZUORA_LOAD_CUSTOM_EVENTS_FAILURE]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const customEventLabel = ZAvailableExtensions.CustomEvent;

    return {
      ...state,
      [customEventLabel]: {
        ...state[customEventLabel],
        lastLoadAttemptSuccessful: false,
      },
    };
  },
};

const workflowHandlers = {
  [ReduxActionTypes.ZUORA_RESET_WORKFLOW_SAVE_STATUS]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const workflowLabel = ZAvailableExtensions.Workflow;

    return {
      ...state,
      [workflowLabel]: {
        ...state[workflowLabel],
        lastSaveAttemptSuccessful: null,
      },
    };
  },

  [ReduxActionTypes.ZUORA_RESET_WORKFLOW_LOAD_STATUS]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const workflowLabel = ZAvailableExtensions.Workflow;

    return {
      ...state,
      [workflowLabel]: {
        ...state[workflowLabel],
        lastLoadAttemptSuccessful: null,
      },
    };
  },

  [ReduxActionTypes.ZUORA_SAVE_WORKFLOWS_SUCCESS]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<ZWorkflow[]>,
  ) => {
    const workflowLabel = ZAvailableExtensions.Workflow;

    return {
      ...state,
      [workflowLabel]: {
        ...state[workflowLabel],
        data: action.payload,
        lastSaveAttemptSuccessful: true,
      },
    };
  },
  [ReduxActionTypes.ZUORA_SAVE_WORKFLOWS_FAILURE]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const workflowLabel = ZAvailableExtensions.Workflow;

    return {
      ...state,
      [workflowLabel]: {
        ...state[workflowLabel],
        lastSaveAttemptSuccessful: false,
      },
    };
  },
  [ReduxActionTypes.ZUORA_LOAD_WORKFLOWS_SUCCESS]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<ZWorkflow[]>,
  ) => {
    const workflowLabel = ZAvailableExtensions.Workflow;

    return {
      ...state,
      [workflowLabel]: {
        ...state[workflowLabel],
        data: action.payload,
        lastLoadAttemptSuccessful: true,
      },
    };
  },
  [ReduxActionTypes.ZUORA_LOAD_WORKFLOWS_FAILURE]: (
    state: ZuoraComponentsReduxState,
  ) => {
    const workflowLabel = ZAvailableExtensions.Workflow;

    return {
      ...state,
      [workflowLabel]: {
        ...state[workflowLabel],
        lastLoadAttemptSuccessful: false,
      },
    };
  },
};

export const handlers = {
  [ReduxActionTypes.ZUORA_SET_COMPONENT_MANAGER_IS_LOADED]: (
    state: ZuoraComponentsReduxState,
    action: ReduxAction<boolean>,
  ) => {
    return {
      ...state,
      componentManagerIsLoaded: action.payload,
    };
  },
  ...objectHandlers,
  ...customFieldHandlers,
  ...customEventHandlers,
  ...workflowHandlers,
};

const zuoraComponentsReducer = createImmerReducer(initialState, handlers);

export default zuoraComponentsReducer;
