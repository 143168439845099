import type { ReduxAction } from "actions/ReduxActionTypes";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { createReducer } from "utils/ReducerUtils";

export interface EmbedPropsState {
  externalUrl: string;
  appName: string;
  pageId: string;
  previousPageId: string;
}

const initialState: EmbedPropsState = {
  externalUrl: "",
  appName: "",
  pageId: "",
  previousPageId: "",
};

export const handlers = {
  [ReduxActionTypes.SET_EMBED_PROPS]: (
    state: EmbedPropsState,
    action: ReduxAction<Record<string, string>>,
  ) => {
    return {
      ...state,
      externalUrl: action.payload.externalUrl,
      appName: action.payload.appName,
      pageId: action.payload.pageId,
    };
  },
  [ReduxActionTypes.SET_EMBED_PAGE_IDS]: (
    state: EmbedPropsState,
    action: ReduxAction<Record<string, string>>,
  ) => {
    return {
      ...state,
      pageId: action.payload.pageId,
      previousPageId: action.payload.previousPageId,
    };
  },
};

export default createReducer(initialState, handlers);
