import { createReducer } from "utils/ReducerUtils";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";

export type ZBaseConstantsState = Record<string, object>;
export type ZInstanceConstantsState = Record<string, object>;

export interface ZConstantsDataState {
  zBaseConstants: ZBaseConstantsState;
  zInstanceConstants: ZInstanceConstantsState;
}

const initialState: ZConstantsDataState = {
  zBaseConstants: {},
  zInstanceConstants: {},
};

const zConstantsReducer = createReducer(initialState, {
  [ReduxActionTypes.FETCH_ZCONSTANTS_SUCCESS]: (
    state: ZConstantsDataState,
    action: ReduxAction<Record<string, object>>,
  ) => {
    return {
      ...state,
      zBaseConstants: action.payload.zConstants,
      zInstanceConstants: action.payload.zInstanceConstants,
    };
  },
  [ReduxActionTypes.CHANGE_APP_ZCONSTANTS_SUCCESS]: (
    state: ZConstantsDataState,
    action: ReduxAction<Record<string, object>>,
  ) => {
    return {
      ...state,
      zBaseConstants: action.payload.zConstants,
    };
  },
  [ReduxActionTypes.CHANGE_APP_ZINSTANCECONSTANTS_SUCCESS]: (
    state: ZConstantsDataState,
    action: ReduxAction<Record<string, object>>,
  ) => {
    return {
      ...state,
      zInstanceConstants: action.payload.zInstanceConstants,
    };
  },
});

export default zConstantsReducer;
