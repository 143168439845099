import React from "react";
import { Button } from "@appsmith/ads";

import Page from "./Page";
import { createMessage, PAGE_CLIENT_ERROR_TITLE } from "ee/constants/messages";
import { flushErrors } from "actions/errorActions";
import { useDispatch } from "react-redux";

// Define the Zuora support URL
const ZUORA_SUPPORT_URL = "https://support.zuora.com/";

interface Props {
  errorCode?: string;
  errorMessage?: string;
}

function ZuoraErrorPage(props: Props) {
  const dispatch = useDispatch();

  return (
    <Page
      cta={
        <Button
          className="button-position"
          endIcon="right-arrow"
          kind="primary"
          onClick={() => {
            dispatch(flushErrors());
            window.open(ZUORA_SUPPORT_URL, "_blank");
          }}
          size="md"
        >
          Contact Zuora Support
        </Button>
      }
      description={props.errorMessage}
      title={createMessage(PAGE_CLIENT_ERROR_TITLE)}
    />
  );
}

export default ZuoraErrorPage;
