import type { AxiosPromise } from "axios";
import type {
  UpdateDefaultBranchRequestParams,
  UpdateDefaultBranchResponse,
} from "./updateDefaultBranchRequest.types";
import GitSyncAPI from "api/GitSyncAPI";
import Api from "api/Api";

export default async function updateDefaultBranchRequest(
  baseArtifactId: string,
  params: UpdateDefaultBranchRequestParams,
): AxiosPromise<UpdateDefaultBranchResponse> {
  return Api.put(
    `${GitSyncAPI.baseURL}/branch/app/${baseArtifactId}/default?branchName=${params.branchName}`,
  );
}
