export enum Condition {
  Warn = "Warn",
  // Error = "Error",
  // Success = "Success",
}

export enum EditorState {
  DATA = "DATA",
  EDITOR = "EDITOR",
  SETTINGS = "SETTINGS",
  LIBRARIES = "LIBRARIES",
  // RULES = "RULES",
  CUSTOM_EVENT = "CUSTOM_EVENT",
  CUSTOM_FIELD = "CUSTOM_FIELD",
  CUSTOM_OBJECT = "CUSTOM_OBJECT",
  WORKFLOW = "WORKFLOW",
}
