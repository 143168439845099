import axios from "axios";
import { DEFAULT_AXIOS_CONFIG } from "ee/constants/ApiConstants";
import { apiRequestInterceptor } from "api/interceptors/request/apiRequestInterceptor";
import { apiSuccessResponseInterceptor } from "api/interceptors/response/apiSuccessResponseInterceptor";
import { apiFailureResponseInterceptor } from "api/interceptors/response/apiFailureResponseInterceptor";

export function apiFactory() {
  // NOTE(Cameron): here is where we set the endpoint for API calls for embedded use case
  const axiosConfigs = DEFAULT_AXIOS_CONFIG;
  const rootDiv: HTMLElement | null = document.getElementById("z_embed_root");
  if (rootDiv?.dataset?.externalUrl != null) {
    axiosConfigs.baseURL = rootDiv.dataset.externalUrl + "/api/";
  }
  const axiosInstance = axios.create(axiosConfigs);

  axiosInstance.interceptors.request.use(apiRequestInterceptor);
  axiosInstance.interceptors.response.use(
    apiSuccessResponseInterceptor,
    apiFailureResponseInterceptor,
  );

  return axiosInstance;
}
