import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";

export const setEmbedProps = (
  externalUrl: string,
  appName: string,
  pageId: string,
) => ({
  type: ReduxActionTypes.SET_EMBED_PROPS,
  payload: {
    externalUrl,
    appName,
    pageId,
  },
});

export const setEmbedPageIds = (pageId: string, previousPageId: string) => ({
  type: ReduxActionTypes.SET_EMBED_PAGE_IDS,
  payload: {
    pageId,
    previousPageId,
  },
});
