import React, { useEffect, useState } from "react";
import type { AppState } from "ee/reducers";
import { getCurrentUser, getUserAuthError } from "selectors/usersSelectors";
import { connect } from "react-redux";
import type { User } from "constants/userConstants";
import { ANONYMOUS_USERNAME } from "constants/userConstants";
import { Redirect } from "react-router";
import {
  ALL_EXTENSIONS_URL,
  APPLICATIONS_URL,
  AUTH_LOGIN_URL,
  BASE_URL,
} from "constants/routes";
import PageLoadingBar from "pages/common/PageLoadingBar";
import ServerUnavailable from "pages/common/ErrorPages/ServerUnavailable";
import ErrorPage from "pages/common/ErrorPage";
import { ERROR_CODES } from "ee/constants/ApiConstants";

interface Props {
  user?: User;
  authError: string;
}

function LandingScreen(props: Props) {
  const [loading, setLoading] = useState(true);
  const [toExtensions, setToExtensions] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const pathname = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  // console.log("Window Location is: ", window.location);
  // console.log("Condition: ", window.location.hostname.includes("store"));

  // 2c92c8f9638fc5750163aa7c513f0871.store.staging2.zuora.com -> public, no alt, no zStore -> 32 chars fixed
  // ibm.store.staging2.zuora.com -> zStoreName
  // store.ibm.com -> altStoreName

  useEffect(() => {
    // Check for error in URL
    const urlError = searchParams.get("error");
    if (urlError) {
      if (window.location.hostname.includes("store")) {
        setError(
          "Please enable third-party cookies in your browser settings and try again.",
        );
      } else {
        setError(urlError);
      }
    }

    if (
      (window.location.hostname.includes("store") &&
        window.location.hostname.includes("zuora.com")) ||
      (window.location.hostname.includes("store.appsmith.com") &&
        pathname === "/")
    ) {
      setToExtensions(true);
    }
    setLoading(false);
  }, [pathname]);

  if (loading) {
    return <PageLoadingBar />;
  }

  // Display error if present in query params
  if (error) {
    return <ErrorPage code={ERROR_CODES.ZUORA_ERROR} errorMessage={error} />;
  }

  if (!loading && props.user && pathname === BASE_URL) {
    if (toExtensions === true) {
      return <Redirect to={ALL_EXTENSIONS_URL} />;
    } else if (props.user.email === ANONYMOUS_USERNAME) {
      return <Redirect to={AUTH_LOGIN_URL} />;
    } else {
      return <Redirect to={APPLICATIONS_URL} />;
    }
  }

  if (props.authError && props.authError.length) {
    return <ServerUnavailable />;
  }

  return <PageLoadingBar />;
}

const mapStateToProps = (state: AppState) => ({
  user: getCurrentUser(state),
  authError: getUserAuthError(state),
});

export default connect(mapStateToProps)(LandingScreen);
