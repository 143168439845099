import type { ReduxAction } from "actions/ReduxActionTypes";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { AppSettingsTabs } from "pages/AppIDE/components/AppSettings/AppSettings";
import { createReducer } from "utils/ReducerUtils";

const initialState: AppSettingsPaneReduxState = {
  isOpen: false,
  context: {
    type: AppSettingsTabs.General,
  },
  zConstantTabIndex: 0,
};

const appSettingsPaneReducer = createReducer(initialState, {
  [ReduxActionTypes.UPDATE_APP_SETTINGS_PANE_SELECTED_TAB]: (
    state: AppSettingsPaneReduxState,
    action: ReduxAction<AppSettingsPaneReduxState>,
  ): AppSettingsPaneReduxState => {
    return {
      ...state,
      isOpen: action.payload.isOpen,
      context: action.payload.context,
      zConstantTabIndex: action.payload.zConstantTabIndex,
    };
  },
  [ReduxActionTypes.SET_SELECTED_ZCONSTANTS_TAB_INDEX]: (
    state: AppSettingsPaneReduxState,
    action: ReduxAction<{ selectedIndex: number }>,
  ): AppSettingsPaneReduxState => {
    return {
      ...state,
      zConstantTabIndex: action.payload.selectedIndex,
    };
  },
});

export interface AppSettingsPaneContext {
  type: AppSettingsTabs;
  pageId?: string;
}

export interface AppSettingsPaneReduxState {
  isOpen: boolean;
  context?: AppSettingsPaneContext;
  zConstantTabIndex: number;
}

export default appSettingsPaneReducer;
