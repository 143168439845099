import React from "react";
import { IDEHeaderTitle } from "@appsmith/ads";

import { createMessage, HEADER_TITLES } from "ee/constants/messages";
import { Omnibar } from "pages/Editor/commons/Omnibar";
import { EditorTitle } from "./EditorTitle";
import { EditorState } from "IDE/enums";
import useLibraryHeaderTitle from "ee/pages/AppIDE/layouts/components/Header/useLibraryHeaderTitle";
import { GitApplicationContextProvider } from "git-artifact-helpers/application/components";
import BuilderHeaderComponent from "./BuilderHeaderComponent";

interface HeaderTitleProps {
  appState: EditorState;
}

const HeaderTitleComponent = ({ appState }: HeaderTitleProps) => {
  const libraryHeaderTitle = useLibraryHeaderTitle();

  switch (appState) {
    case EditorState.DATA:
      return (
        <IDEHeaderTitle
          key={appState}
          title={createMessage(HEADER_TITLES.DATA)}
        />
      );
    case EditorState.EDITOR:
      return <EditorTitle key={appState} />;
    case EditorState.SETTINGS:
      return (
        <IDEHeaderTitle
          key={appState}
          title={createMessage(HEADER_TITLES.SETTINGS)}
        />
      );
    case EditorState.LIBRARIES:
      return <IDEHeaderTitle key={appState} title={libraryHeaderTitle} />;
    default:
      return <EditorTitle key={appState} />;
  }
};

const Header = () => {
  return (
    <GitApplicationContextProvider>
      <BuilderHeaderComponent />
      <Omnibar />
    </GitApplicationContextProvider>
  );
};

export { Header, HeaderTitleComponent };
