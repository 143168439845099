import React, { useEffect } from "react";
import type { InjectedFormProps } from "redux-form";
import { reduxForm, formValueSelector } from "redux-form";
import { AUTH_LOGIN_URL } from "constants/routes";
import { SIGNUP_FORM_NAME } from "ee/constants/forms";
import type { RouteComponentProps } from "react-router-dom";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import {
  ZSIGNUP_PAGE_TITLE,
  FORM_VALIDATION_EMPTY_PASSWORD,
  FORM_VALIDATION_INVALID_EMAIL,
  FORM_VALIDATION_INVALID_PASSWORD,
  createMessage,
  ZSIGNUP_PAGE_SUBTITLE,
  ZUORA_LOGIN,
} from "ee/constants/messages";
import { Callout } from "@appsmith/ads";
import { isEmail, isStrongPassword, isEmptyString } from "utils/formhelpers";

import type { SignupFormValues } from "pages/UserAuth/helpers";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";

import { ZUORA_SIGNUP_SUBMIT_PATH } from "ee/constants/ApiConstants";
import { connect, useSelector } from "react-redux";
import type { AppState } from "ee/reducers";

import { SIGNUP_FORM_EMAIL_FIELD_NAME } from "ee/constants/forms";

import { getIsSafeRedirectURL } from "utils/helpers";
import Container from "pages/UserAuth/Container";
import Helmet from "react-helmet";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { getHTMLPageTitle } from "ee/utils/BusinessFeatures/brandingPageHelpers";
import PageLoadingBar from "pages/common/PageLoadingBar";
import {
  getIsFormLoginEnabled,
  getOrganizationConfig,
} from "ee/selectors/organizationSelectors";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const validate = (values: SignupFormValues) => {
  const errors: SignupFormValues = {};
  if (!values.password || isEmptyString(values.password)) {
    errors.password = createMessage(FORM_VALIDATION_EMPTY_PASSWORD);
  } else if (!isStrongPassword(values.password)) {
    errors.password = createMessage(FORM_VALIDATION_INVALID_PASSWORD);
  }

  const email = values.email || "";
  if (!isEmptyString(email) && !isEmail(email)) {
    errors.email = createMessage(FORM_VALIDATION_INVALID_EMAIL);
  }
  return errors;
};

type SignUpFormProps = InjectedFormProps<
  SignupFormValues,
  { emailValue: string }
> &
  RouteComponentProps<{ email: string }> & { emailValue: string };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ZsignUp(_props: SignUpFormProps) {
  const history = useHistory();
  const isFormLoginEnabled = useSelector(getIsFormLoginEnabled);
  useEffect(() => {
    if (!isFormLoginEnabled) {
      const search = new URL(window.location.href)?.searchParams?.toString();
      history.replace({
        pathname: AUTH_LOGIN_URL,
        search,
      });
    }

    AnalyticsUtil.logEvent("ZSIGNUP_REACHED", {
      referrer: document.referrer,
    });
  }, []);
  const location = useLocation();
  const isBrandingEnabled = useFeatureFlag(
    FEATURE_FLAG.license_branding_enabled,
  );

  const organizationConfig = useSelector(getOrganizationConfig);
  const { instanceName } = organizationConfig;
  const htmlPageTitle = getHTMLPageTitle(isBrandingEnabled, instanceName);

  let showError = false;
  let errorMessage = "";
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.get("error")) {
    errorMessage = queryParams.get("error") || "";
    showError = true;
  }

  const signupURL = new URL(
    `/api/v1/` + ZUORA_SIGNUP_SUBMIT_PATH,
    window.location.origin,
  );
  const appId = queryParams.get("appId");
  if (appId) {
    signupURL.searchParams.append("appId", appId);
  } else {
    const redirectUrl = queryParams.get("redirectUrl");
    if (redirectUrl != null && getIsSafeRedirectURL(redirectUrl)) {
      signupURL.searchParams.append("redirectUrl", redirectUrl);
    }
  }

  const submitFormAndNavigate = async (formData: any) => {
    try {
      const response = await fetch(signupURL.toString(), {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // Handle the response as needed

      // // If the submission was successful, navigate to the form's action URL
      if (response.ok) {
        const redirectUrl = new URL(response.url);
        const params = new URLSearchParams(redirectUrl.search);
        const redirectUrlValue = params.get("redirectUrl");
        if (redirectUrlValue) {
          window.location.href = redirectUrlValue;
          return; // Stop further execution
        }
        // If redirectUrl query parameter not found, redirect to appURL
        window.location.href = response.url;
      }
    } catch (error) {}
  };

  useEffect(() => {
    // Call handleSubmit with the synthetic event to trigger form submission when the component mounts
    // handleSubmit(syntheticEvent);
    submitFormAndNavigate(new FormData());
  }, []);

  const footerSection = (
    <div className="px-2 py-4 flex align-center justify-center text-base text-center text-[color:var(--ads-v2\-color-fg)] text-[14px]">
      {createMessage(ZUORA_LOGIN)}
    </div>
  );

  return (
    <Container
      footer={footerSection}
      subtitle={createMessage(ZSIGNUP_PAGE_SUBTITLE)}
      title={createMessage(ZSIGNUP_PAGE_TITLE)}
    >
      <Helmet>
        <title>{htmlPageTitle}</title>
      </Helmet>

      {showError && <Callout kind="error">{errorMessage}</Callout>}
      <PageLoadingBar />
      <img
        alt="Zuora Logo"
        src="https://nav-assets.zuora.life/img/zuora-wordmark.svg"
      />
    </Container>
  );
}

const selector = formValueSelector(SIGNUP_FORM_NAME);
export default connect((state: AppState, props: SignUpFormProps) => {
  const queryParams = new URLSearchParams(props.location.search);
  return {
    initialValues: {
      email: queryParams.get("email"),
    },
    emailValue: selector(state, SIGNUP_FORM_EMAIL_FIELD_NAME),
  };
}, null)(
  reduxForm<SignupFormValues, { emailValue: string }>({
    validate,
    form: SIGNUP_FORM_NAME,
    touchOnBlur: true,
  })(withRouter(ZsignUp)),
);
