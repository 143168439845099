import React from "react";
import DefaultBranchView from "./DefaultBranchView";
import useBranches from "git/hooks/useBranches";
import { useGitContext } from "git/components/GitContextProvider";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";

export default function DefaultBranch() {
  const { artifactDef } = useGitContext();
  const { branches, isUpdateDefaultBranchLoading, updateDefaultBranch } =
    useBranches();
  const { artifactType } = artifactDef ?? {};
  const isGitProtectedFeatureLicensed = useFeatureFlag(
    FEATURE_FLAG.license_git_branch_protection_enabled,
  );

  return (
    <DefaultBranchView
      artifactType={artifactType ?? null}
      branches={branches}
      isGitProtectedFeatureLicensed={isGitProtectedFeatureLicensed}
      isLoading={isUpdateDefaultBranchLoading}
      updateDefaultBranch={updateDefaultBranch}
    />
  );
}
