import React from "react";
import styled from "styled-components";
import { Icon } from "@appsmith/ads";
import { selectDeployKey } from "git/store/selectors/gitArtifactSelectors";
import useArtifactSelector from "git/hooks/useArtifactSelector";
import { CopyButton } from "./CopyButton";

const KeyContainer = styled.div`
  height: 36px;
  border: 1px solid var(--ads-v2-color-border);
  padding: 8px;
  box-sizing: border-box;
  border-radius: var(--ads-v2-border-radius);
  background-color: #fff;
  align-items: center;
  display: flex;
`;

const KeyType = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: var(--ads-v2-color-fg);
  font-weight: 700;
  margin-right: 8px;
`;

const KeyText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  font-size: 10px;
  color: var(--ads-v2-color-fg);
  direction: rtl;
  margin-right: 8px;
`;

const TabSSHKey: React.FC = () => {
  const sshKey = useArtifactSelector(selectDeployKey);
  const sshKeyType = sshKey?.includes("rsa") ? "RSA" : "ECDSA";

  return (
    <KeyContainer>
      <Icon
        color="var(--ads-v2-color-fg)"
        name="key-2-line"
        size="md"
        style={{ marginRight: 4 }}
      />
      <KeyType>{sshKeyType}</KeyType>
      <KeyText>{sshKey}</KeyText>
      <CopyButton tooltipMessage="Copy SSH Key" value={sshKey} />
    </KeyContainer>
  );
};

export default TabSSHKey;
