import type { UpdateDefaultBranchInitPayload } from "../store/actions/updateDefaultBranchActions";
import { gitArtifactActions } from "../store/gitArtifactSlice";
import type { GitArtifactPayloadAction } from "../store/types";
import { call, put } from "redux-saga/effects";
import { validateResponse } from "sagas/ErrorSagas";
import { showZuoraToast } from "ZToast/ZToast.actions";
import {
  createMessage,
  UPDATE_DEFAULT_BRANCH_SUCCESS,
} from "ee/constants/messages";
import updateDefaultBranchRequest from "git/requests/updateDefaultBranchRequest";
import type {
  UpdateDefaultBranchRequestParams,
  UpdateDefaultBranchResponse,
} from "git/requests/updateDefaultBranchRequest.types";
import handleApiErrors from "./helpers/handleApiErrors";
import { getLogToSentryFromResponse } from "utils/helpers";

export default function* updateDefaultBranchSaga(
  action: GitArtifactPayloadAction<UpdateDefaultBranchInitPayload>,
) {
  const { artifactDef, artifactId } = action.payload;
  let response: UpdateDefaultBranchResponse | undefined;

  try {
    const params: UpdateDefaultBranchRequestParams = {
      branchName: action.payload.branchName,
    };

    response = yield call(
      updateDefaultBranchRequest,
      artifactDef.baseArtifactId,
      params,
    );
    const isValidResponse: boolean = yield validateResponse(
      response,
      false,
      getLogToSentryFromResponse(response),
    );

    if (isValidResponse) {
      yield put(gitArtifactActions.updateDefaultBranchSuccess({ artifactDef }));
      yield put(
        gitArtifactActions.fetchBranchesInit({
          artifactDef,
          artifactId,
          pruneBranches: true,
        }),
      );
      yield put(
        showZuoraToast(
          createMessage(
            UPDATE_DEFAULT_BRANCH_SUCCESS,
            action.payload.branchName,
          ),
          {
            kind: "success",
          },
        ),
      );
    }
  } catch (e) {
    const error = handleApiErrors(e as Error, response);

    if (error) {
      yield put(
        gitArtifactActions.updateDefaultBranchError({ artifactDef, error }),
      );
    }
  }
}
