import React, { useCallback } from "react";
import { BuilderHeader } from "zds";
import { GitOpsTab } from "git";
import useOps from "git/hooks/useOps";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { publishApplication } from "ee/actions/applicationActions";
import { getIsGitConnected } from "selectors/gitSyncSelectors";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import { Tooltip, ToggleButton } from "@appsmith/ads";
import type { AppState } from "ee/reducers";
import { APP_MODE } from "entities/App";
import { getAppMode } from "ee/selectors/applicationSelectors";
import { setPreviewModeInitAction } from "actions/editorActions";
import { previewModeSelector } from "selectors/editorSelectors";
import { createMessage, EDITOR_HEADER } from "ee/constants/messages";
import { altText } from "utils/helpers";

interface BuilderHeaderProps {
  title?: string;
}

const BuilderHeaderComponent: React.FC<BuilderHeaderProps> = ({
  title = "Extension Studio",
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { toggleOpsModal } = useOps();

  const applicationId = useSelector(getCurrentApplicationId);
  const isGitConnected = useSelector(getIsGitConnected);
  const isPreviewMode = useSelector(previewModeSelector);
  const appMode = useSelector(getAppMode);
  const mode = useSelector((state: AppState) => state.entities.app.mode);
  const isViewMode = mode === APP_MODE.PUBLISHED;

  const goToPreviousPath = () => {
    history.goBack();
  };

  const handlePublish = useCallback(() => {
    if (applicationId) {
      dispatch(publishApplication(applicationId));
    }
  }, [applicationId, dispatch]);

  const handleClickDeploy = useCallback(() => {
    if (isGitConnected) {
      console.log("Calling toggleOpsModal with deploy");
      toggleOpsModal(true, GitOpsTab.Deploy);
    } else {
      handlePublish();
    }
  }, [handlePublish, isGitConnected, toggleOpsModal]);

  const togglePreviewMode = useCallback(() => {
    dispatch(setPreviewModeInitAction(!isPreviewMode));
  }, [dispatch, isPreviewMode]);

  return (
    <BuilderHeader
      actions={[
        {
          label: "Preview",
          onClick: togglePreviewMode, // Directly toggle preview mode
          variant: "tertiary",
        },
        {
          label: "Deploy",
          onClick: handleClickDeploy,
          variant: "contained",
        },
      ]}
      closeAction={{
        icon: "keyboard_arrow_left",
        label: "Back",
        onClick: goToPreviousPath,
      }}
      e2e="myBuilderHeader"
      title={title}
    >
      {!isViewMode && (
        <Tooltip
          content={
            <>
              {createMessage(EDITOR_HEADER.previewTooltip.text)}
              <span style={{ marginLeft: 20 }}>
                {`${altText()} ${createMessage(
                  EDITOR_HEADER.previewTooltip.shortcut,
                )}`}
              </span>
            </>
          }
          isDisabled={appMode !== APP_MODE.EDIT}
          placement="bottom"
        >
          <ToggleButton
            data-testid={`${isPreviewMode ? "preview" : "edit"}-mode`}
            icon="play-line"
            isSelected={isPreviewMode}
            onClick={togglePreviewMode}
            size="md"
          />
        </Tooltip>
      )}
    </BuilderHeader>
  );
};

export default BuilderHeaderComponent;
