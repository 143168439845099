import React from "react";

function SsoCallbackPage() {
  const postMessageToParent = () => {
    window.opener.postMessage(
      {
        app_id: new URLSearchParams(window.location.search).get("app_id"),
        page_id: new URLSearchParams(window.location.search).get("sso_page_id"),
        path: new URLSearchParams(window.location.search).get("path"),
        type: "ssoLoginMessage",
      },
      "*",
      undefined,
    );
    window.close();
  };
  return <script>{postMessageToParent()}</script>;
}

export default SsoCallbackPage;
