import { createArtifactAction } from "../helpers/createArtifactAction";
import type { GitAsyncErrorPayload } from "../types";

export interface UpdateDefaultBranchInitPayload {
  artifactId: string;
  branchName: string;
}

export const updateDefaultBranchInitAction =
  createArtifactAction<UpdateDefaultBranchInitPayload>((state) => {
    state.apiResponses.updateDefaultBranch.loading = true;
    state.apiResponses.updateDefaultBranch.error = null;

    return state;
  });

export const updateDefaultBranchSuccessAction = createArtifactAction(
  (state) => {
    state.apiResponses.updateDefaultBranch.loading = false;

    return state;
  },
);

export const updateDefaultBranchErrorAction =
  createArtifactAction<GitAsyncErrorPayload>((state, action) => {
    const { error } = action.payload;

    state.apiResponses.updateDefaultBranch.loading = false;
    state.apiResponses.updateDefaultBranch.error = error;

    return state;
  });
