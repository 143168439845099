import type { WFCollectionData } from "ee/reducers/entityReducers/wfActionsReducer";
import {
  ENTITY_TYPE,
  EvaluationSubstitutionType,
} from "ee/entities/DataTree/types";
import type {
  WFActionEntity,
  WFActionEntityConfig,
} from "ee/entities/DataTree/types";

export const generateDataTreeWFAction = (
  action: WFCollectionData,
): {
  unEvalEntity: WFActionEntity;
  configEntity: WFActionEntityConfig;
} => {
  return {
    unEvalEntity: {
      actionId: encodeURIComponent(action.config.name),
      // Data is always set to undefined in the unevalTree
      // Action data is updated directly to the dataTree (see updateActionData.ts)
      data: undefined,
      isLoading: action.isLoading,
      //   responseMeta: {
      //     statusCode: action.data?.statusCode,
      //     isExecutionSuccess: action.data?.isExecutionSuccess || false,
      //     headers: action.data?.headers,
      //   },
      config: action.config.active_version,
      ENTITY_TYPE: ENTITY_TYPE.WFACTION,
    },
    configEntity: {
      actionId: encodeURIComponent(action.config.name),
      name: action.config.name,
      dynamicBindingPathList: [],
      ENTITY_TYPE: ENTITY_TYPE.WFACTION,
      bindingPaths: {},
      reactivePaths: {
        config: EvaluationSubstitutionType.TEMPLATE,
        data: EvaluationSubstitutionType.TEMPLATE,
        isLoading: EvaluationSubstitutionType.TEMPLATE,
      },
      dependencyMap: {},
    },
  };
};
