export const handleBadRequestError = async () => {
  // const errorCode = error?.response?.data?.responseMeta.error?.code;

  // if (
  //   error?.response?.status === 400 &&
  //   SERVER_ERROR_CODES.VERSION_MISMATCH.includes("" + errorCode)
  // ) {
  //   const responseData = error?.response?.data;
  //   const serverVersion = (responseData?.data as { serverVersion: string })
  //     .serverVersion;

  //   handleVersionMismatch(getAppsmithConfigs().appVersion.id, serverVersion);
  // }

  return null;
};
