import React from "react";
import { Helmet } from "react-helmet";
import store from "store";

interface Props {
  name?: string;
  description?: string;
}

function AppViewerHtmlTitle(props: Props) {
  const { description, name } = props;

  // if no name is passed, just return null
  if (!name) return null;
  // do not change window title when embedded
  const state = store.getState();
  const externalUrl = state?.embedProps?.externalUrl;
  if (externalUrl != null && externalUrl != "") {
    return null;
  }

  return (
    <Helmet>
      <title>{name}</title>
      {description && <meta content={description} name="description" />}
    </Helmet>
  );
}

export default AppViewerHtmlTitle;
