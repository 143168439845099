import React from "react";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import Login from "pages/UserAuth/Login";
import PageNotFound from "pages/common/ErrorPages/PageNotFound";
import SsoPage from "pages/common/Sso";
import SsoCallbackPage from "pages/common/SsoCallback";
import { requiresUnauth } from "./requiresAuthHOC";
import { useSelector } from "react-redux";
import { getThemeDetails, ThemeMode } from "selectors/themeSelectors";
import type { AppState } from "ee/reducers";
import { ThemeProvider } from "styled-components";
import FooterLinks from "./FooterLinks";
import { useIsMobileDevice } from "utils/hooks/useDeviceDetect";
import { getAssetUrl } from "ee/utils/airgapHelpers";
import { getOrganizationConfig } from "ee/selectors/organizationSelectors";
import { getAppsmithConfigs } from "ee/configs";
import { SentryRoute } from "components/SentryRoute";
import ZsignUp from "./ZsignUp";

export function UserAuth() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const lightTheme = useSelector((state: AppState) =>
    getThemeDetails(state, ThemeMode.LIGHT),
  );
  const isMobileDevice = useIsMobileDevice();
  const organizationConfig = useSelector(getOrganizationConfig);
  const { cloudHosting } = getAppsmithConfigs();
  const disableFooter = window.location.pathname === "/user/sso";

  return (
    <ThemeProvider theme={lightTheme}>
      {/* TODO: (Albin) - chnages this to ads-v2 variable once  branding is sorted out. */}
      <div
        className={`absolute inset-0 flex flex-col overflow-y-auto auth-container bg-[color:var(--ads-color-background-secondary)] ${
          !isMobileDevice ? "p-4" : "px-6 py-12"
        } t--auth-container justify-between`}
      >
        {isMobileDevice && (
          <img
            className="h-8 mx-auto"
            src={getAssetUrl(organizationConfig.brandLogoUrl)}
          />
        )}
        <Switch location={location}>
          <SentryRoute component={Login} exact path={`${path}/login`} />
          <SentryRoute component={ZsignUp} exact path={`${path}/zsignup`} />
          <SentryRoute component={SsoPage} exact path={`${path}/sso`} />
          <SentryRoute
            component={SsoCallbackPage}
            exact
            path={`${path}/sso_callback`}
          />
          <SentryRoute component={PageNotFound} />
        </Switch>
        {cloudHosting && !disableFooter && <FooterLinks />}
      </div>
    </ThemeProvider>
  );
}

export default requiresUnauth(UserAuth);
