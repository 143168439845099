/* eslint-disable no-console */
// This file must be executed as early as possible to ensure the preloads are triggered ASAP
import "./preload-route-chunks";
// Initialise eval worker instance
import "utils/workerInstances";

import React from "react";
import "./wdyr";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import "@appsmith/ads-old/src/themes/default/index.css";
import "@appsmith/ads/src/__theme__/default/index.css";
import { ThemeProvider } from "styled-components";
import { appInitializer } from "utils/AppUtils";
import store, { runSagaMiddleware } from "./store";
import { LayersContext, Layers } from "constants/Layers";
import AppRouter from "ee/AppRouter";
import { getCurrentThemeDetails } from "selectors/themeSelectors";
import { connect } from "react-redux";
import type { AppState } from "ee/reducers";
import { Toast } from "@appsmith/ads";
import "./assets/styles/index.css";
import "./polyfills";
import GlobalStyles from "globalStyles";
// enable autofreeze only in development

import AppErrorBoundary from "./AppErrorBoundry";
import log from "loglevel";
import { FaroErrorBoundary } from "@grafana/faro-react";
import { isTracingEnabled } from "instrumentation/utils";
import { ZComponentPersistenceProvider } from "ZComponentPersistenceProvider";
import { ZPostMessageProvider } from "ZPostMessageProvider/ZPostMessageProvider";
import { AutoThemeProvider } from "zds";
import ZToastComponent from "ZToast/ZToastComponent";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import { setEmbedProps } from "ee/actions/embedPropActions";

runSagaMiddleware();

appInitializer();

isTracingEnabled() &&
  (async () => {
    try {
      await import(
        /* webpackChunkName: "instrumentation" */ "./instrumentation"
      );
    } catch (e) {
      log.error("Error loading telemetry script", e);
    }
  })();

if (window.CDN_URL) {
  __webpack_public_path__ = window.CDN_URL;
} else {
  console.warn("CDN_URL not detected - using default container path");
}

function App() {
  return (
    <FaroErrorBoundary fallback={<div>An error has occured</div>}>
      <Provider store={store}>
        <LayersContext.Provider value={Layers}>
          <ThemedAppWithProps />
        </LayersContext.Provider>
      </Provider>
    </FaroErrorBoundary>
  );
}

class ThemedApp extends React.Component<{
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentTheme: any;
  customStyle: string;
}> {
  componentDidMount() {
    this.injectCustomCSS(this.props.customStyle);
  }

  componentDidUpdate(prevProps: { customStyle: string }) {
    if (prevProps.customStyle !== this.props.customStyle) {
      this.injectCustomCSS(this.props.customStyle);
    }
  }

  injectCustomCSS(customCSS: string) {
    let styleTag = document.getElementById("custom-css-style");
    if (!styleTag) {
      styleTag = document.createElement("style");
      styleTag.id = "custom-css-style";
      document.head.appendChild(styleTag);
    }
    styleTag.innerHTML = customCSS;
  }
  render() {
    return (
      <ZComponentPersistenceProvider>
        <ZPostMessageProvider>
          <AutoThemeProvider>
            <ThemeProvider theme={this.props.currentTheme}>
              <Toast />
              <ZToastComponent />
              <GlobalStyles />
              <AppErrorBoundary>
                <AppRouter />
              </AppErrorBoundary>
            </ThemeProvider>
          </AutoThemeProvider>
        </ZPostMessageProvider>
      </ZComponentPersistenceProvider>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  currentTheme: getCurrentThemeDetails(state),
  customStyle: getCurrentApplication(state)?.customStyle || "",
});

const ThemedAppWithProps = connect(mapStateToProps)(ThemedApp);

const rootDiv: HTMLElement | null = document.getElementById("z_embed_root");
if (rootDiv?.dataset?.pageId == null) {
  ReactDOM.render(<App />, document.getElementById("root"));
}

class ExtensionsWidget extends HTMLElement {
  connectedCallback() {
    // Create a mount point for React
    const mountPoint = document.createElement("div");
    this.appendChild(mountPoint);

    // Inject styles
    const style = document.createElement("style");
    style.textContent = `
      @import url("https://cdn.zuora.com/@platform-ui/design-system@2.83.6/dist/zds.css");
    `;
    this.appendChild(style);

    const externalUrl = this.getAttribute("data-external-url") || "";
    const appName = this.getAttribute("data-app-name") || "";
    const pageId = this.getAttribute("data-page-id") || "";

    store.dispatch(setEmbedProps(externalUrl, appName, pageId));

    const regex = /(?<=store.)[a-zA-Z\d.]*(?=.zuora)/;
    const match = regex.exec(externalUrl);
    if (match) {
      const cdnUrl = "https://cdn.store." + match[0] + ".zuora.com/widget/";
      __webpack_public_path__ = cdnUrl;
    }

    // Render React app
    ReactDOM.render(<App />, mountPoint);
  }
}

customElements.define("extensions-widget", ExtensionsWidget);

// expose store when run in Cypress
// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as any).Cypress) {
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).store = store;
}
